<div class="container text-center">
  <div class="register form-signin text-center">
    <img class="logo-badge mb-4" src="/assets/img/xapa-logo.png" alt="">
    <p class="lead">Register your account</p>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">

      <p>
        <mat-error *ngIf="registrationResult && !registrationResult.success && (registrationResult.message != null)">
          {{registrationResult.message}}
        </mat-error>
      </p>

      <!-- email -->
      <mat-form-field>
        <mat-label>Email:</mat-label>
        <input matInput formControlName="email" required placeholder="Enter your email address.">
        <mat-error *ngFor="let error of getErrors(form.get('email')!, 'Email')">
          {{error}}
        </mat-error>
      </mat-form-field>

      <!-- password -->
      <mat-form-field>
        <mat-label>Password:</mat-label>
        <input matInput type="password" formControlName="password" required placeholder="Enter your password.">
        <mat-error *ngFor="let error of getErrors(form.get('password')!, 'Password')">
          {{error}}
        </mat-error>
      </mat-form-field>

      <!-- confirm password -->
      <mat-form-field>
        <mat-label>Confirm Password:</mat-label>
        <input matInput type="password" formControlName="confirmPassword" required placeholder="Enter your password again.">
        <mat-error *ngFor="let error of getErrors(form.get('confirmPassword')!, 'ConfirmPassword')">
          {{error}}
        </mat-error>
      </mat-form-field>

      <div>
        <button class="w-100 btn btn-lg btn-primary mb-4" type="submit">
          Register
        </button>

        <button class="w-100 btn btn-lg btn-light" [routerLink]="['/']">
          Cancel
        </button>

        <div class="p-4"><a [routerLink]="['/login']">Already have an account?</a></div>

        <p class="mt-5 mb-3 text-muted">&copy; 2017–2024</p>
      </div>

    </form>
  </div>
</div>
