
<app-nav-menu></app-nav-menu>

<main>
  <router-outlet />
</main>

<div class="container-fluid">
  <div class="row">

    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4" [class.col-md-9]="isLoggedIn" [class.col-lg-10]="isLoggedIn">
      <app-sidebar></app-sidebar>
      <router-outlet />
    </main>
  </div>
</div>



