import { formatDate } from "@angular/common";
import { HttpParams } from '@angular/common/http';
import { Chart } from "chart.js";

export interface ResponseResult<T> {
    success: boolean;
    message: string;
    data: T;
}

export interface ReportEntry {
    bucket: string;
    value: number;
}
  
export interface Report {
    name: string;
    entries: ReportEntry[];
}

export enum activeUserScope {
    daily = 1,
    weekly = 2,
    monthly = 3
}

export enum userBehaviorType {
    time = 1,
    session = 2,
}

export enum questSelectType {
    xperience = 1,
    quest = 2,
    nodeTitle = 3
}

export function updateChart(chart: any, report: Report, color: string, dateFormat: string, locale: string) {
    if (chart != null) {
      removeData(chart);
      chart.data.datasets[0].label = report.name;
      var ds = chart.data.datasets[0];
      if (null != ds) {
        ds.label = report.name;
        ds.backgroundColor = color;
        ds.borderColor = color;
        ds.pointBackgroundColor = color;
      }
      for (const elem of report.entries) {
        var label = formatDate(elem.bucket, dateFormat, locale);
        addData(chart, label, elem.value);
      }
    }
  }

function addData(chart: Chart, label: any, newData: number) {
    if (chart == null)
      return;

    if (null != chart.data) {
      if (chart.data.labels != null) {
        chart.data.labels.push(label);
      }
      chart.data.datasets.forEach((dataset) => {
        dataset.data.push(newData);
      });
      chart.update();
    }
  }

function removeData(chart: Chart) {
    if (chart == null
      || chart.data == null
      || chart.data.labels == null)
      return;

    let total = chart.data.labels.length;

    while (total >= 0) {
      chart.data.labels.pop();
      chart.data.datasets[0].data.pop();
      total--;
    }
    chart.update();
  }

export function getCompanysParams(params: HttpParams): HttpParams {
  let companyTitles = [];
  try {
    companyTitles = JSON.parse(localStorage.getItem('companyTitles') || '[]');
  } catch (err: any) {
    console.error(err);
  }
  const companys = companyTitles?.length ? companyTitles?.join(',') : '';
  if (companys) {
    params = params.set('companys', companys);
  }
  return params;
}

