<nav *ngIf="isLoggedIn" id="sidebarMenu" class="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
  <div class="position-sticky pt-3 sidebar-sticky">
    <ul class="nav flex-column" [hidden]="!isRegisteredUser()">
      <li class="nav-item">
        <a class="nav-link" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-current="page" [routerLink]="['/home']" [routerLinkActive]="['active']">
          <span data-feather="home" class="align-text-bottom"></span>
          Home
        </a>
      </li>

      <li class="nav-item">
        <a class="nav-link" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" [routerLink]="['/engagement']" [routerLinkActive]="['active']">
          <span data-feather="users" class="align-text-bottom"></span>
          Engagement
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" [routerLink]="['/retention']" [routerLinkActive]="['active']">
          <span data-feather="filter" class="align-text-bottom"></span>
          Retention
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" [routerLink]="['/user-behavior']" [routerLinkActive]="['active']">
          <span data-feather="sliders" class="align-text-bottom"></span>
          User Behavior
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" [routerLink]="['/quest-analytics']" [routerLinkActive]="['active']">
          <span data-feather="bar-chart-2" class="align-text-bottom"></span>
          Quest Analytics
        </a>
      </li>

      <li class="nav-item" *ngIf="isAdmin()">
        <a class="nav-link" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" [routerLink]="['/user-management']" [routerLinkActive]="['active']">
          <span data-feather="bar-chart-2" class="align-text-bottom"></span>
          User Management
        </a>
      </li>

      <ng-container *ngIf="isAdmin()">

        <mat-divider class="mt-4"></mat-divider>

        <li class="nav-item">
          <a class="nav-link px-3"
             data-bs-toggle="collapse" data-bs-target="#sidebarMenu"
             [routerLink]="['/admin-user']" [routerLinkActive]="['active']">
            Admin
          </a>
        </li>

      </ng-container>

    </ul>

  </div>
</nav>
