
<app-page-title title="Users" [hiddenCompany]="true"></app-page-title>

<p *ngIf="!users"><em>Loading...</em></p>

<table mat-table [dataSource]="users"
      class="mat-elevation-z8 table table-striped"
      [hidden]="!users">

  <!-- Id Column -->
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef>ID</th>
    <td mat-cell *matCellDef="let user">{{user.id}}</td>
  </ng-container>

  <!-- Email Column -->
  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef>Email</th>
    <td mat-cell *matCellDef="let user">{{user.email}}</td>
  </ng-container>

  <!-- IsConfirmed Column -->
  <ng-container matColumnDef="isConfirmed">
    <th mat-header-cell *matHeaderCellDef>Email Confirmed</th>
    <td mat-cell *matCellDef="let user">{{user.isConfirmed ? "Yes" : "No"}}</td>
  </ng-container>

  <!-- Roles Column -->
  <ng-container matColumnDef="roles">
    <th mat-header-cell *matHeaderCellDef>Roles</th>
    <td mat-cell *matCellDef="let user">
<!--
      {{user.roles}}
      <mat-slide-toggle></mat-slide-toggle>
      <mat-slide-toggle></mat-slide-toggle>
  -->
      <span style="display:flex; flex-direction:row;">

        <ng-container *ngIf="isRegisteredUser(user.roles); else unregisteredUser">
          <button mat-button color="warn" (click)="revokeAccess(user)">Revoke Access</button>

          <ng-container *ngIf="isAdministrator(user.roles); else regularUser">
            <button mat-button color="warn"(click)="revokeAdmin(user)">Revoke Admin</button>
          </ng-container>
          <ng-template #regularUser>
            <button mat-button (click)="grantAdmin(user)">Grant Admin</button>
          </ng-template>
        </ng-container>

        <ng-template #unregisteredUser>
          <button mat-button (click)="grantAccess(user)">Grant Access</button>
        </ng-template>

        <!--<button mat-button>Grant Admin</button>
        <button mat-button color="primary">Revoke Admin</button>-->
      </span>

      <!--
      <button mat-flat-button color="primary">Revoke Access</button>
      <button mat-flat-button color="accent">Grant Admin</button>
      <button mat-flat-button color="warn">Revoke Admin</button>
      <button mat-flat-button disabled>Disabled</button>
        -->

    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<!-- Pagination directive -->
<mat-paginator [hidden]="!users"
              (page)="getData($event)"
              [pageSize]="10"
              [pageSizeOptions]="[1, 10, 20, 50]"
              showFirstLastButtons></mat-paginator>

<!--
<table class='table table-striped' aria-labelledby="tableLabel"
      [hidden]="!users && !users.data">
  <thead>
    <tr>
      <th>ID</th>
      <th>Email</th>
      <th>Roles</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let user of users.data">
      <td>{{ user.id }}</td>
      <td>{{ user.email }}</td>
      <td>{{ user.roles }}</td>
    </tr>
  </tbody>
</table>
-->
