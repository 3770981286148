import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpClientModule, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { AuthInterceptor } from './auth/auth.interceptor';
import { ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { DATE_PIPE_DEFAULT_OPTIONS } from "@angular/common";
import { LoginComponent } from './auth/login.component';
import { AngularMaterialModule } from './angular-material.module';
import { RegisterComponent } from './auth/register.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { AdminUserComponent } from './admin-user/admin-user.component';
import { EngagementComponent } from './engagement/engagement.component';
import { RetentionComponent } from './retention/retention.component';
import { UserBehaviorComponent } from './user-behavior/user-behavior.component';
import { QuestAnalyticsComponent } from './quest-analytics/quest-analytics.component';
import { LandingComponent } from './landing/landing.component';
import { ProfileComponent } from './profile/profile.component';
import { UnregisteredComponent } from './unregistered/unregistered.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { CompanyFilterComponentComponent } from './company-filter-component/company-filter-component.component';
import { PageTitleComponent } from './page-title/page-title.component';
import { TableSortArrowIconComponent } from './table-sort-arrow-icon/table-sort-arrow-icon.component';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    CounterComponent,
    FetchDataComponent,
    LoginComponent,
    RegisterComponent,
    SidebarComponent,
    AdminUserComponent,
    EngagementComponent,
    RetentionComponent,
    UserBehaviorComponent,
    QuestAnalyticsComponent,
    LandingComponent,
    ProfileComponent,
    UnregisteredComponent,
    UserManagementComponent,
    CompanyFilterComponentComponent,
    PageTitleComponent,
    TableSortArrowIconComponent
   ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AngularMaterialModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    /*provideHttpClient(
      withInterceptorsFromDi(),
      withInterceptors([AuthInterceptor])
    ),*/
    provideAnimationsAsync(),
    {
      provide: DATE_PIPE_DEFAULT_OPTIONS,
      useValue: { dateFormat: "longDate" }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
