
  <div class="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center bg-light way-back">
    <div class="col-md-5 p-lg-5 mx-auto my-5 bg-light p-4">
      <h1 class="display-4 fw-normal">Welcome to the Xapa Dashboard</h1>
      <p class="lead fw-normal">Get analytics-enriched!<br/>Create an account to get started.</p>
      <a class="btn btn-outline-secondary" href="#">Coming soon</a>
    </div>
    <div class="product-device shadow-sm d-none d-md-block"></div>
    <div class="product-device product-device-2 shadow-sm d-none d-md-block"></div>
  </div>

