
<nav class="navbar navbar-expand-md sticky-top navbar-dark bg-primary">

  <a class="navbar-brand col-md-3 col-lg-2 px-3 fs-6" [routerLink]="['/']"><img class="logo-badge-small" src="/assets/img/xapa-logo.png" alt="">Xapa Dashboard</a>

  <div class="ms-auto me-4 text-white-80" id="navbar-submenu">
    <ul class="navbar-nav me-auto" style="text-align:right;">
      <li *ngIf="isLoggedIn" class="nav-item">
        <a class="nav-link p-0 m-0 d-none d-sm-none d-md-block " [routerLink]="['/profile']" [routerLinkActive]="['active']">{{ getEmail() }} {{ isAdmin() ? '(admin)' : '' }}</a>
        <a class="nav-link m-0 p-0" href="javascript:void(0);" (click)="onLogout()">Sign Out</a>
      </li>
      <li *ngIf="!isLoggedIn" class="nav-item">
        <a class="nav-link m-0 p-0" href="javascript:void(0);" [routerLink]="['/login']">Sign In</a>
      </li>
    </ul>
  </div>

  <button *ngIf="isLoggedIn" class="navbar-toggler d-md-none collapsed me-4" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu"
          aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

</nav>
