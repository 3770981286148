import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FormGroup, FormControl, Validators, AbstractControl, AsyncValidatorFn
} from '@angular/forms';

import { BaseFormComponent } from '../base-form.component';
import { AuthService } from '../auth/auth.service';
import { LoginRequest } from '../auth/login-request';
import { LoginResult } from '../auth/login-result';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.css'
})
export class LoginComponent extends BaseFormComponent implements OnInit {
  title?: string;
  loginResult?: LoginResult;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService) {
    super();
  }

  ngOnInit() {
    this.form = new FormGroup({
      email: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required)
    });
  }

  onSubmit() {
    var loginRequest = <LoginRequest>{};
    loginRequest.email = this.form.controls['email'].value;
    loginRequest.password = this.form.controls['password'].value;
    this.authService.login(loginRequest)
      .subscribe({
        next: result => {
          console.log(result);
          this.loginResult = result;

          if (result.success) {
            this.router.navigate(["/home"]);
          }
        },
        error: result => {
          console.log(result);
          if (result.status == 401) {
            this.loginResult = result.error;
          }
        }
      });
  }
}
