import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from "./environments/environment"
import { AppModule } from './app/app.module';

export function getBaseUrl() {
  return environment.baseURL;
  //"https://localhost:40443/"; // document.getElementsByTagName('base')[0].href;
}

const providers = [
  { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] }
];

platformBrowserDynamic(providers).bootstrapModule(AppModule, {
  ngZoneEventCoalescing: true
})
  .catch(err => console.error(err));
