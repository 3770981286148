import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, Inject, OnInit, LOCALE_ID } from '@angular/core';
import { Chart } from 'chart.js';
import { Report, ResponseResult, updateChart, getCompanysParams } from '../util';

@Component({
  selector: 'app-retention',
  templateUrl: './retention.component.html',
  styleUrl: './retention.component.css'
})
export class RetentionComponent implements OnInit{
  public chart: any = null;
  public chartTitle: string = "Retention";
  public selectedOption: string = "btnradio2";
  public option: string = "1";
  public isLoading: boolean = false;
  public reportLabel = "Retention 1-day";
  public interval: number = 1;

  public retentionResult?: Report;
  private colorDay1: string = '#007bff';
  private colorDay3: string = '#732BF5';
  private colorDay7: string = '#32CD32';        
  private colorDay28: string = '#FFD700';

  private dateFormatDaily: string = 'MM-dd-yyyy';


  constructor(@Inject(LOCALE_ID) public locale: string,
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string) {
    this.showData(1);
  }

  ngOnInit(): void {
    this.createChart();
  }

  onCompanyChange() {
    this.showData(this.interval);
  }

  createChart() {
    var ctx = "RetentionChart";

    if (this.chart != null) {
      return;
    }
    else {

      this.chart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: [],
          datasets: [{
            label: 'Retention 1-day',
            data: [],
            pointRadius: 1.5,
            backgroundColor: '#007bff',
            borderColor: '#007bff',
            borderWidth: 2,
            pointBackgroundColor: '#007bff',
          }]
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
              suggestedMax: 100,
              ticks: {
                color: "#b6baca",
                callback: function(value) {
                  return value + '%';
                },
              },
              grid: {
              },
              border: {
              },
            },
            x: {
              ticks: {
                color: "#b6baca",
              },
              grid: {
              },
              border: {
              },
            },
          },
          layout: {
            padding: 20,
          },
          transitions: {
            show: {
              animations: {
                x: {
                  from: 0
                },
                y: {
                  from: 0
                }
              }
            },
            hide: {
              animations: {
                x: {
                  to: 0
                },
                y: {
                  to: 0
                },
              }
            }
          },
          plugins: {
            tooltip: {
              callbacks: {
                label: function(context) {
                  let label = context.dataset.label || '';

                  if (label) {
                    label += ': ';
                  }
                  if (context.parsed.y !== null) {
                    label += Math.round(context.parsed.y) + '%';
                  }
                  return label;
                }
              }
            }
          }
        },
      });

    }
  }

  onChangeMetric(val: any) {
    console.log("Change Tab! " + val + " -> " + this.option);
  }


  showData(param: number) {
    this.isLoading = true;
    this.interval = param;
    const params = new HttpParams().set('interval', param);
    this.http.get<ResponseResult<Report>>(this.baseUrl + 'api/Analytics/retention', { params: getCompanysParams(params) }).subscribe(
      {
        next: result => {
          if (result.success) {
            this.retentionResult = result.data;
            updateChart(this.chart, result.data, this.getLineColor(param), this.dateFormatDaily, this.locale);
          } else {
            console.error(result.message);
          }
          this.isLoading = false;
        },
        error: error => {
          console.error(error)
        }
      }).add(() => {
        this.isLoading = false;
      });
  }

  getLineColor(param: number) {
    switch (param) {
      case 1:
        return this.colorDay1;
      case 3:
        return this.colorDay3;
      case 7:
        return this.colorDay7;
      case 28:
      return this.colorDay28;
      default:
        return this.colorDay1;
    }
  }
  showError(message: string) {

  }
}
