import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild, AfterViewInit, inject } from '@angular/core';
import { FormsModule, FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { getCompanysParams } from '../util';
import { MatSort } from '@angular/material/sort';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';

interface User {
  email: string;
  playerName: string;
  companyInfo: {
    title: string,
    companyName: string
  };
  xperiencesCompleted: number;
  questStarted: number;
  questCompleted: number;
  since: string;
  sessionCount: number;
  lastSignIn: string;
}

interface Company {
  companyName: string,
  title: string,
}

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrl: './user-management.component.css'
})
export class UserManagementComponent implements OnInit, AfterViewInit {
  // table variable
  displayedColumns: string[] = [
    'email',
    'playerName',
    'companyInfo',
    'xperiencesCompleted',
    'questCompleted',
    'since',
    'sessionCount',
    'lastSignIn',
    'operation'
  ]
  users: MatTableDataSource<User> = new MatTableDataSource<User>([]);
  public pageEvent: PageEvent;
  isLoading: boolean = false;
  sortHighlight: any = {
    active: 'lastSignIn',
    direction: 'DESC',
  };
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    public dialog: MatDialog,
    private snackBar: MatSnackBar) {
    this.pageEvent = new PageEvent();
    this.pageEvent.pageIndex = 0;
    this.pageEvent.pageSize = 20;
  }
  ngAfterViewInit(): void {
    this.sort.sortChange.subscribe(() => {
      this.sortHighlight = {
        active: this.sort?.active || 'lastSignIn',
        direction: (this.sort?.direction || 'desc').toUpperCase(),
      };
      this.getTableData(this.pageEvent);
    });
  }

  ngOnInit(): void {
    this.getTableData(this.pageEvent);
    this.getCompanys();
  }

  onCompanyChange() {
    this.getTableData(this.pageEvent);
  }

  getTableData(event: PageEvent) {
    this.isLoading = true;
    let pageSizeChange = this.pageEvent.pageSize !== event.pageSize;
    this.pageEvent.pageSize = event.pageSize;
    const url = this.baseUrl + 'api/Analytics/user-management';
    const params = new HttpParams()
      .set('sortColumn', this.sort?.active || 'lastSignIn')
      .set('sortType', (this.sort?.direction || 'desc').toUpperCase())
      .set('pageIndex', pageSizeChange ? '0' : event.pageIndex.toString())
      .set('pageSize', event.pageSize.toString());
    this.http.get<any>(url, {
      params: getCompanysParams(params)
    })
    .subscribe(
      {
        next: result => {
          if (result.success) {
            this.paginator.length = result.totalCount;
            this.paginator.pageIndex = result.pageIndex;
            this.paginator.pageSize = result.pageSize;
            this.users = new MatTableDataSource<User>(result.data || []);
            if (result.errors != null) {
              const message = result?.errors?.length ? result?.errors?.join("; ") : (result?.message || '');
              this.showMessage(message);
              console.error(message);
            }
          } else {
            console.error(result.message);
          }
          this.isLoading = false;
        },
        error: error => {
          console.error(error);
          this.paginator.length = 0;
          this.paginator.pageIndex = event.pageIndex;
          this.paginator.pageSize = event.pageSize;
          this.users = new MatTableDataSource<User>([]);
        }
      }).add(() => {
        this.isLoading = false;
      });
  }

  getCompanys() {
    const url = this.baseUrl + 'api/Analytics/company-list';
    this.http.get<any>(url).subscribe(
      {
        next: result => {
          if (result.success) {
            companys = result?.data || [];
          } else {
            console.error(result.message);
          }
        },
        error: error => { console.error(error); }
      }
    );
  }

  openEditDialog(editUser: User): void {
    const dialogRef = this.dialog.open(UserManagementDialog, {
      minWidth: '370px',
      minHeight: '200px',
      autoFocus: false,
      data: editUser,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) return;
      const url = this.baseUrl + 'api/Analytics/user-company';
      const params = new HttpParams()
        .set('player', result.playerName)
        .set('companyTitle', result.selectedCompany);
      this.http.put<any>(url, {}, { params }).subscribe(
        {
          next: res => {
            if (res.success) {
              const index = this.users?.data?.findIndex(v => v.playerName === result.playerName);
              if (index !== -1) {
                this.users.data[index].companyInfo = res.data;
                this.users.connect();
              }
            } else {
              const message = res?.errors?.length ? res?.errors?.join("; ") : (res.message || '');
              this.showMessage(message || `Failed to update company of player: ${result.playerName}`);
            }
          },
          error: error => {
            this.showMessage(`Failed to update company of player: ${result.playerName}`);
            console.error(error);
          }
        }
      );
    });
  }

  showMessage(message: string, duration: number = 5000) {
    this.snackBar.open(message, "", {
      duration: duration,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: 'message',
    });
  }
}

@Component({
  selector: 'user-management-dialog',
  templateUrl: './user-management-dialog.component.html',
  styleUrl: './user-management.component.css',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatSelectModule,
    MatIconModule
  ],
})
export class UserManagementDialog {
  public panelOpen = false;
  public selectedCompany = new FormControl('');
  public selectedCompanyName = '';
  public isDisabled: boolean = true;
  companyList = companys;
  playerName = '';
  email = '';

  constructor(
    public dialogRef: MatDialogRef<UserManagementDialog>,
    @Inject(MAT_DIALOG_DATA) public data: User) {
      this.selectedCompany.valueChanges.subscribe(value => {
        this.isDisabled = false;
        const selectedCompany = this.companyList?.find(v => v.title === value);
        this.selectedCompanyName = selectedCompany?.companyName || selectedCompany?.title || '';
      });
      this.selectedCompany.setValue(data?.companyInfo?.title || '');
      this.playerName = data?.playerName || '';
      this.email = data?.email || '';
      this.isDisabled = true;
    }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  clean(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.selectedCompany.setValue('');
  }
}

var companys: Company[] = [];
