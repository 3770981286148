import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FormGroup, FormControl, Validators, AbstractControl, AsyncValidatorFn
} from '@angular/forms';

import { BaseFormComponent } from '../base-form.component';
import { AuthService } from '../auth/auth.service';
import { RegistrationRequest } from '../auth/registration-request';
import { RegistrationResult } from '../auth/registration-result';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrl: './register.component.css'
})
export class RegisterComponent extends BaseFormComponent implements OnInit {
  title?: string;
  registrationResult?: RegistrationResult;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService) {
    super();
  }

  ngOnInit() {
    this.form = new FormGroup({
      email: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
      confirmPassword: new FormControl('', Validators.required)
    });
  }

  onSubmit() {
    var registrationRequest = <RegistrationRequest>{};

    registrationRequest.email = this.form.controls['email'].value;
    registrationRequest.password = this.form.controls['password'].value;
    registrationRequest.confirmPassword = this.form.controls['confirmPassword'].value;

    this.authService.register(registrationRequest)
      .subscribe({
        next: result => {
          console.log(result);
          this.registrationResult = result;

          if (result.success) {
            this.router.navigate(["/"]);
          }
        },
        error: result => {
          if (result != null && result.status != null) {
            this.registrationResult = result.error;
          }
        }
      });
  }
}
