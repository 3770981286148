import { Component } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.css'
})
export class SidebarComponent {
  private destroySubject = new Subject();
  isLoggedIn: boolean = false;
  isExpanded = false;

  constructor(private authService: AuthService,
    private router: Router) {

    this.authService.authStatus
      .pipe(takeUntil(this.destroySubject))
      .subscribe(result => {
        this.isLoggedIn = result;
      });
  }

  isAdmin() {
    return this.authService.isAdmin();
  }

  isRegisteredUser() {
    return this.authService.isRegisteredUser();
  }
}
