import { Component, Inject, OnInit, output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-company-filter-component',
  templateUrl: './company-filter-component.component.html',
  styleUrls: ['./company-filter-component.component.css']
})
export class CompanyFilterComponentComponent implements OnInit {
  onSelectChange = output();

  isChange: boolean = false;

  companyTitles = new FormControl<string[]>({ value: [], disabled: false });
  companyList: Company[] = [];
  allCompanyChecked: boolean = false;
  companyIndeterminate: boolean = false;
  selectedCompanyName: string = '';

  constructor(@Inject('BASE_URL') private baseUrl: string,
    private http: HttpClient,
    private snackBar: MatSnackBar) {}

  ngOnInit(): void {
    this.companyTitles.valueChanges.subscribe(value => {
      this.isChange = true;
      const selectedCompany = this.companyList?.find((v) => v.title === (value?.find(v => '*none*' !== v) || value?.[0] || ''));
      this.selectedCompanyName = (selectedCompany?.companyName || selectedCompany?.title) || '';
      if (value != null && value.length > 0) {
        this.allCompanyChecked = !!this.companyList?.map(v => v.title)?.every(element => value.includes(element));
        this.companyIndeterminate = !this.allCompanyChecked;
      } else {
        this.allCompanyChecked = false;
        this.companyIndeterminate = false;
      }
      localStorage.setItem('companyTitles', JSON.stringify(value));
    });
    this.getCompanyList();
  }

  getCompanyList() {
    const url = this.baseUrl + 'api/Analytics/company-list';
    this.http.get<any>(url).subscribe(
      {
        next: result => {
          if (result.success) {
            this.companyList = [{
              companyName: '-- None --',
              title: '*none*',
            }].concat(result?.data || []);
            try {
              this.companyTitles.setValue(JSON.parse(localStorage.getItem('companyTitles') || '[]'));
            } catch(err: any) {
              console.error(err);
            }
          } else {
            console.error(result.message);
          }
          const message = result?.errors?.length ? result?.errors?.join("; ") : (result.message || '');
          if (message) {
            this.showMessage(message);
          }
        },
        error: error => { console.error(error); }
      }
    );
  }

  allChange(event: any) {
    if (event.checked) {
      this.companyTitles.setValue(this.companyList.map(value => value.title || ''));
    } else {
      this.companyTitles.setValue([]);
    }
  }

  onOpened() {
    this.isChange = false;
  }

  onClosed() {
    if (this.isChange) {
      this.onSelectChange.emit();
    }
  }

  showMessage(message: string, duration: number = 5000) {
    this.snackBar.open(message, "", {
      duration: duration,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: 'message',
    });
  }
}

interface Company {
  companyName: string,
  title: string
}
