import { ActivatedRoute, Router } from "@angular/router";
import { BaseFormComponent } from "../base-form.component";
import { AuthService } from "../auth/auth.service";
import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ResetPasswordRequest } from "../auth/reset-password-request";
import { ResetPasswordResult } from "../auth/reset-password-result";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.css'
})
export class ProfileComponent extends BaseFormComponent implements OnInit {
  title?: string;
  resetPasswordResult?: ResetPasswordResult;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService) {
    super();
  }

  ngOnInit() {
    this.form = new FormGroup({
      password: new FormControl('', Validators.required),
      newPassword: new FormControl('', Validators.required),
      confirmPassword: new FormControl('', Validators.required)
    });
  }

  onSubmit() {
    var resetPasswordRequest = <ResetPasswordRequest>{};

    resetPasswordRequest.email = this.authService.getEmail();
    resetPasswordRequest.password = this.form.controls['password'].value;
    resetPasswordRequest.newPassword = this.form.controls['newPassword'].value;
    resetPasswordRequest.confirmPassword = this.form.controls['confirmPassword'].value;

    this.authService.resetPassword(resetPasswordRequest)
      .subscribe({
        next: result => {
          if (result.success) {
            this.resetPasswordResult = result;
            this.authService.logout();
          }
        },
        error: result => {
          if (result != null && result.status != null) {
            this.resetPasswordResult = result.error;
          }
        }
      });
  }
}
