import { Component, Input, output } from '@angular/core';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrl: './page-title.component.css'
})
export class PageTitleComponent {
  @Input() title = '';
  @Input() hiddenCompany = false;
  onCompanyChange = output();

  onSelectChange() {
    this.onCompanyChange.emit();
  }
}
