import { Component, inject, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

const ARROW_ICON = `<svg t="1724223742576" class="icon" viewBox="0 0 1433 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id=`+
`"40478" width="256" height="256"><path d="M716.8 1024L0 0h1433.6z" fill="#cacaca" p-id="40479"></path></svg>`;

@Component({
  selector: 'app-table-sort-arrow-icon',
  templateUrl: './table-sort-arrow-icon.component.html',
  styleUrl: './table-sort-arrow-icon.component.css',
})
export class TableSortArrowIconComponent {
  @Input() sort: string = '';

  constructor() {
    const iconRegistry = inject(MatIconRegistry);
    const sanitizer = inject(DomSanitizer);
    iconRegistry.addSvgIconLiteral('arrow-icon', sanitizer.bypassSecurityTrustHtml(ARROW_ICON));
  }
}
