<!--
<h1 id="tableLabel">Weather forecast</h1>

<p>This component demonstrates fetching data from the server.</p>

<p *ngIf="!forecasts"><em>Loading...</em></p>

<table class='table table-striped' aria-labelledby="tableLabel" *ngIf="forecasts">
  <thead>
    <tr>
      <th>Date</th> 
      <th>Temp. (C)</th>
      <th>Temp. (F)</th>
      <th>Summary</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let forecast of forecasts">
      <td>{{ forecast.date }}</td>
      <td>{{ forecast.temperatureC }}</td>
      <td>{{ forecast.temperatureF }}</td>
      <td>{{ forecast.summary }}</td>
    </tr>
  </tbody>
</table>

  -->
<main class="col-md-9 col-lg-10 px-md-4">
  <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">

    <h3 class="h2">{{chartTitle}}</h3>

    <div class="btn-group" role="group" aria-label="Basic radio toggle button group" data-toggle="buttons">
      <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" checked>
      <label class="btn btn-outline-primary" for="btnradio1" (click)="showDAU();">Daily</label>

      <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off">
      <label class="btn btn-outline-primary" for="btnradio2" (click)="showWAU();">Weekly</label>

      <input type="radio" class="btn-check" name="btnradio" id="btnradio3" autocomplete="off">
      <label class="btn btn-outline-primary" for="btnradio3" (click)="showMAU();">Monthly</label>
    </div>

  </div>

  <!--
  <div class="btn-group" role="group" aria-label="Basic radio toggle button group"
       style="border:dashed 10px red; display:flex; flex-direction:row; justify-items:flex-end">
    <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" checked>
    <label class="btn btn-outline-primary" for="btnradio1">Day</label>

    <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off">
    <label class="btn btn-outline-primary" for="btnradio2">Week</label>

    <input type="radio" class="btn-check" name="btnradio" id="btnradio3" autocomplete="off">
    <label class="btn btn-outline-primary" for="btnradio3">Month</label>
  </div>

  <div>
    <a class="button" routerLink="bar" routerLinkActive="activebutton">Bar</a>
    <a class="button" routerLink="line" routerLinkActive="activebutton">Line</a>
    <a class="button" routerLink="pie" routerLinkActive="activebutton">Pie</a>
    <a class="button" routerLink="doughnut" routerLinkActive="activebutton">Doughnut</a>
  </div>
  -->

  <canvas class="my-4 w-100" id="MyChart" width="900" height="380">{{ chart }}</canvas>

  <table class='table table-striped' aria-labelledby="tableLabel"
         [hidden]="!dau">
    <thead>
      <tr>
        <th>Date</th>
        <th>Unique Users</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="dailyList">
        <tr *ngFor="let entry of dau">
          <td>{{ entry.item1 | date:'fullDate' }}</td>
          <td>{{ entry.item2 }}</td>
        </tr>
      </ng-container>
      <ng-container *ngIf="weeklyList">
        <tr *ngFor="let entry of dau">
          <td>{{ entry.item1 | date:'shortDate' }}</td>
          <td>{{ entry.item2 }}</td>
        </tr>
      </ng-container>
      <ng-container *ngIf="monthlyList">
        @for (entry of monthlyList.labels; track entry; let  i = $index) {
          <tr>
            <td>{{ entry | date:'MMM y' }}</td>
            <td>{{ monthlyList.dataSets[0][i] }}</td>
          </tr>
        }
      </ng-container>
    </tbody>
  </table>
</main>
