<div class="company-select-wrapper">
  <mat-form-field class="company-select xapa-select">
    <mat-label>Company</mat-label>
    <mat-select
      [formControl]="companyTitles"
      multiple
      panelClass="company-select-panel xapa-select-panel"
      (opened)="onOpened()"
      (closed)="onClosed()"
    >
      <mat-select-trigger>
        {{selectedCompanyName || ''}}
        @if ((companyTitles.value?.length || 0) > 1) {
          <span class="example-additional-selection">
            (+{{(companyTitles.value?.length || 0) - 1}} {{companyTitles.value?.length === 2 ? 'other' : 'others'}})
          </span>
        }
      </mat-select-trigger>
      <mat-option
        *ngFor="let company of companyList"
        [value]="company['title']"
        [ngClass]="{'null-option': '*none*' === company['title']}"
      >
        {{company['companyName'] || company['title']}}
      </mat-option>
      <mat-checkbox
        class="company-all"
        color="primary"
        (change)="allChange($event)"
        [checked]="allCompanyChecked"
        [indeterminate]="companyIndeterminate"
      >
        All
      </mat-checkbox>
    </mat-select>
  </mat-form-field>
</div>
