<main class="col-md-9 col-lg-10 px-md-4">
  <div [hidden]="!isLoading" id="pluswrap">
    <div class="plus spinner-border" style="width: 4rem; height: 4rem;" role="status">
    </div>
  </div>
  <app-page-title [title]="chartTitle" (onCompanyChange)="onCompanyChange()">
    <div style="margin: 6px 0 6px 0;" class="btn-group" role="group" aria-label="Basic radio toggle button group" data-toggle="buttons">
      <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" checked>
      <label class="btn btn-outline-primary" for="btnradio1" (click)="showData(1);">1-day</label>

      <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off">
      <label class="btn btn-outline-primary" for="btnradio2" (click)="showData(3);">3-day</label>

      <input type="radio" class="btn-check" name="btnradio" id="btnradio3" autocomplete="off">
      <label class="btn btn-outline-primary" for="btnradio3" (click)="showData(7);">7-day</label>

      <input type="radio" class="btn-check" name="btnradio" id="btnradio4" autocomplete="off">
      <label class="btn btn-outline-primary" for="btnradio4" (click)="showData(28);">28-day</label>
    </div>
  </app-page-title>

  <canvas class="my-4 w-100" id="RetentionChart" width="800" height="480">{{ chart }}</canvas>

  <table class='table table-striped' aria-labelledby="tableLabel"
         >
    <thead>
      <tr>
        <th>Date</th>
        <th>{{reportLabel}}</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="retentionResult">
        @for (entry of retentionResult.entries; track entry; let  i = $index) {
        <tr>
          <td>{{ entry.bucket| date:'fullDate' }}</td>
          <td>{{ entry.value / 100 | percent }}</td>
        </tr>
        }
      </ng-container>
    </tbody>
  </table>
</main>
