<div class="container text-center">
  <div *ngIf="null != resetPasswordResult && resetPasswordResult.success" class="text-center">
    <p class="lead">Your password was successfully changed. Please sign in again.</p>
  </div>

  <div *ngIf="null == resetPasswordResult || !resetPasswordResult.success" class="register form-signin text-center">
    <p class="lead">Reset your password</p>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">

      <p>
        <mat-error *ngIf="resetPasswordResult && !resetPasswordResult.success && (resetPasswordResult.message != null)">
          {{resetPasswordResult.message}}
        </mat-error>
      </p>

      <!-- password -->
      <mat-form-field>
        <mat-label>Password:</mat-label>
        <input matInput type="password" formControlName="password" required placeholder="Enter your password.">
        <mat-error *ngFor="let error of getErrors(form.get('password')!, 'Password')">
          {{error}}
        </mat-error>
      </mat-form-field>

      <!-- password -->
      <mat-form-field>
        <mat-label>New Password:</mat-label>
        <input matInput type="password" formControlName="newPassword" required placeholder="Enter your new password.">
        <mat-error *ngFor="let error of getErrors(form.get('newPassword')!, 'NewPassword')">
          {{error}}
        </mat-error>
      </mat-form-field>

      <!-- confirm password -->
      <mat-form-field>
        <mat-label>Confirm Password:</mat-label>
        <input matInput type="password" formControlName="confirmPassword" required placeholder="Enter your password again.">
        <mat-error *ngFor="let error of getErrors(form.get('confirmPassword')!, 'ConfirmPassword')">
          {{error}}
        </mat-error>
      </mat-form-field>

      <div>
        <button class="w-100 btn btn-lg btn-primary mb-4" type="submit">
          Reset Password
        </button>
      </div>

     </form>
   </div>
 </div>
