<div class="col-md-9 col-lg-10 px-md-4">
  <div [hidden]="!isLoading" id="pluswrap">
    <div class="plus spinner-border" style="width: 4rem; height: 4rem;" role="status">
    </div>
  </div>
  <div #questTitle class="quest-title">
    <app-page-title title="Per MC Quest Report" (onCompanyChange)="onCompanyChange()">
      <mat-form-field class="select xapa-select">
        <mat-label>Xperience</mat-label>
        <mat-select *ngIf="xperiencesOptionalList.length > 0" 
                    [formControl]="xperiences"
                    multiple 
                    (closed)="getFilterOptional(xperiences.value!, quests.value!)"
                    panelClass="custom-select-panel xapa-select-panel">
          <mat-select-trigger>
            {{xperiences.value?.[0] || ''}}
            @if ((xperiences.value?.length || 0) > 1) {
              <span class="example-additional-selection">
                (+{{(xperiences.value?.length || 0) - 1}} {{xperiences.value?.length === 2 ? 'other' : 'others'}})
              </span>
            }
          </mat-select-trigger>
          <mat-option *ngFor="let option of xperiencesOptionalList" [value]="option">{{option}}</mat-option>
          <mat-checkbox class="sticky-option"
          [color]="allSelectColor"
          (click)="toggleAllSelection(questSelectType.xperience)"
          [checked]="allXperiencesSelected"
          [indeterminate]="isXperienceIndeterminate"
          [disableRipple]="true">
        All 
        </mat-checkbox>
        </mat-select>
        <mat-select *ngIf="xperiencesOptionalList.length == 0">
          <mat-option>No Xperiences found</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="select xapa-select">
        <mat-label>Quest</mat-label>
        <mat-select *ngIf="questsOptionalList.length > 0 || (xperiences.value?.length ?? 0) == 0"
                    [formControl]="quests" 
                    multiple
                    (closed)="getFilterOptional(xperiences.value!, quests.value!)"
                    panelClass="custom-select-panel xapa-select-panel">
          <mat-select-trigger>
            {{quests.value?.[0] || ''}}
            @if ((quests.value?.length || 0) > 1) {
              <span class="example-additional-selection">
                (+{{(quests.value?.length || 0) - 1}} {{quests.value?.length === 2 ? 'other' : 'others'}})
              </span>
            }
          </mat-select-trigger>
          <mat-option *ngFor="let option of questsOptionalList" [value]="option">{{option}}</mat-option>
          <mat-checkbox class="sticky-option" 
                        [color]="allSelectColor"
                        (click)="toggleAllSelection(questSelectType.quest)"
                        [checked]="allQuetsSelected"
                        [indeterminate]="isQuestIndeterminate">
            All 
          </mat-checkbox>
        </mat-select>
        <mat-select *ngIf="(xperiences.value?.length ?? 0) > 0 && questsOptionalList.length == 0">
          <mat-option>No Quests found</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="select xapa-select">
        <mat-label>NodeTitle</mat-label>
        <mat-select *ngIf="nodeTitlesOptionalList.length > 0 || (quests.value?.length ?? 0) == 0" 
                    [formControl]="nodeTitles"
                    (closed)="getTableData(xperiences.value!, quests.value!, nodeTitles.value!, pageEvent)"
                    multiple
                    panelClass="custom-select-panel xapa-select-panel">
          <mat-select-trigger>
            {{nodeTitles.value?.[0] || ''}}
            @if ((nodeTitles.value?.length || 0) > 1) {
              <span class="example-additional-selection">
                (+{{(nodeTitles.value?.length || 0) - 1}} {{nodeTitles.value?.length === 2 ? 'other' : 'others'}})
              </span>
            }
          </mat-select-trigger>
          <mat-option *ngFor="let option of nodeTitlesOptionalList" [value]="option">{{option}}</mat-option>
          <mat-checkbox class="sticky-option" 
                        [color]="allSelectColor"
                        (click)="toggleAllSelection(questSelectType.nodeTitle)"
                        [checked]="allNodeTitlesSelected"
                        [indeterminate]="isNodeTitleIndeterminate">
            All 
          </mat-checkbox>
        </mat-select>
        <mat-select *ngIf="(quests.value?.length ?? 0) > 0 && nodeTitlesOptionalList.length == 0">
          <mat-option>No NodeTitles found</mat-option>
        </mat-select>
      </mat-form-field>
    </app-page-title>
  </div>
  <div class="content" [hidden]="!dataSource || dataSource.data.length === 0">
    <div class="table-container">
      <table class="mat-elevation-z8 table table-striped" mat-table [dataSource]="dataSource">
        <!-- ResultText Column -->
        <ng-container matColumnDef="ResultText">
          <th mat-header-cell *matHeaderCellDef> ResultText </th>
          <td mat-cell *matCellDef="let element" class="textColumn"> {{element.resultText}} </td>
          <td mat-footer-cell *matFooterCellDef>Total</td>
        </ng-container>
  
        <!-- Count Column -->
        <ng-container matColumnDef="Count">
          <th mat-header-cell *matHeaderCellDef> Count </th>
          <td mat-cell *matCellDef="let element" class="countColumn"> {{element.count}} </td>
          <td mat-footer-cell *matFooterCellDef>{{totalCount}}</td>
        </ng-container>
  
        <!-- Percent Column -->
        <ng-container matColumnDef="Percent">
          <th mat-header-cell *matHeaderCellDef> Percent </th>
          <td mat-cell *matCellDef="let element" class="percentColumn"> {{element.percentage | percent:'1.0-2'}} </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns;" class="table-head" [ngStyle]="tableHeaderDynamicStyles"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-row"></tr>
        <tr mat-footer-row *matFooterRowDef="displayedColumns;" class="total-row"></tr>
      </table>
    </div>
    <mat-paginator
      class="paginator"
      (page)="getTableData(xperiences.value!, quests.value!, nodeTitles.value!, $event)"
      [pageSizeOptions]="[20, 50, 100]"
      showFirstLastButtons
      aria-label="Select page of periodic elements ">
  </mat-paginator>

  </div>
  <div class="NoData" [hidden]="!isRequested || !dataSource || dataSource.data.length > 0">No Data Found</div>
</div>
