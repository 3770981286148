import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { Report, ResponseResult, getCompanysParams } from '../util';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  isLoading: boolean = false ;
  registeredUsers: number = 0;
  public DAU: number = 0;
  public WAU?: Report;
  public MAU?: Report;
  public totalQuestsStarted: number = 0;
  public totalQuestsCompleted: number = 0;
  public totalXperiencesCompleted: number = 0;
  public retentionDay1?: Report;
  public retentionDay7?: Report;
  constructor(@Inject(LOCALE_ID) public locale: string,
  private http: HttpClient,
  @Inject('BASE_URL') private baseUrl: string) {

}

  ngOnInit(): void {
    this.getHomeData();
  }

  getHomeData() {
    this.isLoading = true;
    const params = new HttpParams();
    this.http.get<ResponseResult<HomeData>>(this.baseUrl + 'api/Analytics/home-report', { params: getCompanysParams(params) }).subscribe(
      {
        next: result => {
          if (result.success) {
            this.registeredUsers = result.data.totalRegistrations;
            this.DAU = result.data.dau;
            this.WAU = result.data.wau;
            this.MAU = result.data.mau;
            this.totalQuestsStarted = result.data.totalQuestsStarted;
            this.totalQuestsCompleted = result.data.totalQuestsCompleted;
            this.totalXperiencesCompleted =result.data.totalXperiencesCompleted;
            this.retentionDay1 = result.data.retention1Day;
            this.retentionDay7 = result.data.retention7Day;
          } else {
            console.error(result.message);
          }
          this.isLoading = false;
        },
        error: error => {
          console.error(error);
        }
      }).add(() => {
        this.isLoading = false;
      });
  }

  onCompanyChange() {
    this.getHomeData();
  }
}

interface HomeData {
  totalRegistrations: number;
  mau: Report;
  wau: Report;
  dau: number;
  totalQuestsStarted: number;
  totalQuestsCompleted: number;
  totalXperiencesCompleted: number;
  retention1Day: Report;
  retention7Day: Report;
}
