<main class="col-md-9 col-lg-10 px-md-4">
  <div [hidden]="!isLoading" id="pluswrap">
    <div class="plus spinner-border" style="width: 4rem; height: 4rem;" role="status">
    </div>
  </div>
  <app-page-title title="Home" (onCompanyChange)="onCompanyChange()"></app-page-title>

  <div class="home-content">
    <div class="overview-boxes">

      <div class="box">
        <div class="right-side">
          <div class="box-topic">Registered Users</div>
          <div class="number">{{ registeredUsers }}</div>
          <div class="indicator">
            <i class='bx bx-up-arrow-alt'></i>
            <span class="text">Lifetime</span>
          </div>
        </div>
        <!--<i class='bx bx-cart-alt cart'></i>-->
      </div>

      <div class="box">
        <div class="right-side">
          <div class="box-topic">MAU</div>
          <div class="number">{{ MAU == null ? 0 : MAU.entries.at(-1)?.value ?? 0 }}</div>
          <div class="indicator">
            <i class='bx bx-up-arrow-alt'></i>
            <span class="text">
              {{ MAU == null ? 'Down' : MAU!.entries[0].value > (MAU.entries.at(-1)?.value ?? 0) ? 'Down' : 'Up'}} from last month
            </span>
          </div>
        </div>
        <!--<i class='bx bxs-cart-add cart two'></i>-->
      </div>

      <div class="box">
        <div class="right-side">
          <div class="box-topic">WAU</div>
          <div class="number">{{ WAU == null ? 0 : WAU.entries.at(-1)?.value ?? 0 }}</div>
          <div class="indicator">
            <i class='bx bx-up-arrow-alt'></i>
            <span class="text">
              {{ WAU == null ? 'Down' : WAU.entries[0].value > (WAU.entries.at(-1)?.value ?? 0) ? 'Down' : 'Up'}} from last week
            </span>
          </div>
        </div>
        <!--<i class='bx bxs-cart-add cart three'></i>-->
      </div>

      <div class="box">
        <div class="right-side">
          <div class="box-topic">DAU</div>
          <div class="number">{{DAU}}</div>
          <div class="indicator">
            <i class='bx bx-up-arrow-alt'></i>
            <span class="text">Average for past 7 days</span>
          </div>
        </div>
        <!--<i class='bx bx-cart cart four'></i>-->
      </div>

      <div class="box">
        <div class="right-side">
          <div class="box-topic">Total Quests Started</div>
          <div class="number">{{ totalQuestsStarted }}</div>
          <div class="indicator">
            <i class='bx bx-down-arrow-alt down'></i>
            <span class="text">Lifetime</span>
          </div>
        </div>
        <!--<i class='bx bxs-cart-download cart five'></i>-->
      </div>

      <div class="box">
        <div class="right-side">
          <div class="box-topic">Total Quests Completed</div>
          <div class="number">{{ totalQuestsCompleted }}</div>
          <div class="indicator">
            <i class='bx bx-down-arrow-alt down'></i>
            <span class="text">Lifetime</span>
          </div>
        </div>
        <!--<i class='bx bxs-cart-download cart six'></i>-->
      </div>

      <div class="box">
        <div class="right-side">
          <div class="box-topic">Total Xperiences Completed</div>
          <div class="number">{{ totalXperiencesCompleted }}</div>
          <div class="indicator">
            <i class='bx bx-down-arrow-alt down'></i>
            <span class="text">Lifetime</span>
          </div>
        </div>
        <!--<i class='bx bxs-cart-download cart seven'></i>-->
      </div>

      <div class="box">
        <div class="right-side">
          <div class="box-topic">1-day Retention</div>
          <div class="number">{{ (retentionDay1 == null ? 0 : retentionDay1.entries.at(-1)?.value ?? 0) / 100 | percent }}</div>
          <div class="indicator">
            <i class='bx bx-down-arrow-alt down'></i>
            <span class="text">
              {{ retentionDay1 == null ? 'Down' : retentionDay1.entries[0].value >= (retentionDay1.entries.at(-1)?.value ?? 0) ? 'Down' : 'Up' }} From yesterday
            </span>
          </div>
        </div>
        <!--<i class='bx bxs-cart-download cart eight'></i>-->
      </div>

      <div class="box">
        <div class="right-side">
          <div class="box-topic">7-day Retention</div>
          <div class="number">{{ (retentionDay7 == null ? 0 : retentionDay7.entries.at(-1)?.value ?? 0) / 100 | percent }}</div>
          <div class="indicator">
            <i class='bx bx-down-arrow-alt down'></i>
            <span class="text">
              {{ retentionDay7 == null ? 'Down' : retentionDay7.entries[0].value >= (retentionDay7.entries.at(-1)?.value ?? 0) ? 'Down' : 'Up' }} From yesterday
            </span>
          </div>
        </div>
        <!--<i class='bx bxs-cart-download cart nine'></i>-->
      </div>

    </div>
  </div>
</main>
