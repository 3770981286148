import { Component, Inject, OnInit, LOCALE_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Chart } from 'chart.js/auto';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-fetch-data',
  templateUrl: './fetch-data.component.html',
  styleUrls: ['./fetch-data.component.css']
})
export class FetchDataComponent implements OnInit {
  public forecasts?: WeatherForecast[];
  public dau?: DailyUniqueUsers[];
  public chart: any = null;
  public chartTitle: string = "Engagement";
  public selectedOption: string = "btnradio2";
  public option: string = "1";

  public dailyList?: DailyUniqueUsers[];
  public weeklyList?: DailyUniqueUsers[];
  public monthlyList?: Report;


  private colorDAU: string = '#007bff';
  private colorWAU: string = '#C41E3A';
  private colorMAU: string = '#32CD32';

  private dateFormatDAU: string = 'MM-dd-yyyy';
  private dateFormatWAU: string = 'MM-dd-yyyy';
  private dateFormatMAU: string = 'MMM-yyyy';

  constructor(@Inject(LOCALE_ID) public locale: string,
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string) {


    /*http.get<WeatherForecast[]>(baseUrl + 'WeatherForecast').subscribe(result => {
      this.forecasts = result;
      console.debug(result);
    }, error => console.error(error));*/


    /*http.get<DailyUniqueUsers[]>(baseUrl + 'api/Analytics/GetDAU').subscribe(result => {
      this.dau = result;
      this.updateChart('Daily Active Users', this.colorDAU, this.dateFormatDAU);
      console.debug(result);
    }, error => console.error(error));*/

    this.showDAU();
  }

  ngOnInit(): void {
    this.createChart();
  }

  createChart() {
    var ctx = "MyChart";

    if (this.chart != null) {
      return;
    }
    else {
      //var ctx = document.getElementById('MyChart');

      this.chart = new Chart(ctx, {
        type: 'line',
        data: {
          /*labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
          datasets: [{
              label: '# of Votes',
              data: [12, 19, 3, 5, 2, @Model.EventCount],
              borderWidth: 1
          }]*/
          labels: [], //[
          // @foreach(var entry in Model.Entries)
          // {
          //@: '@(entry.Item1)',
          // }
          //],
          datasets: [{
            label: 'Daily Active Users (DAU)',
            data: [],//[
            //@foreach(var entry in Model.Entries)
            //{
            //@: '@(entry.Item2)',
            //}            
            //],
            pointRadius: 1.5,
            //lineTension: 0.1,
            backgroundColor: '#007bff',
            borderColor: '#007bff',
            borderWidth: 2,
            pointBackgroundColor: '#007bff'
          }]
        },
        options: {
          //animation: false,
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                color: "#b6baca",
              },
              grid: {
                //drawTicks: false,
              },
              border: {
                // dash: [5, 10],
              },
            },
            x: {
              ticks: {
                color: "#b6baca",
              },
              grid: {
                //display: false,
              },
              border: {
                //display: false,
                //dash: [5, 10],
              },
            },
          },
          //responsive: true,
          //maintainAspectRatio: false,
          layout: {
            padding: 20
          },
          transitions: {
            show: {
              animations: {
                x: {
                  from: 0
                },
                y: {
                  from: 0
                }
              }
            },
            hide: {
              animations: {
                x: {
                  to: 0
                },
                y: {
                  to: 0
                }
              }
            }
          }
        },
      });

    }
  }

  updateChart(label: string, color:string, dateFormat:string) {
    console.error("Update Chart...");
    var ctx = "MyChart";
    if (this.chart != null) {
      this.removeData(this.chart);
      console.error("OKAY");
      if (this.dau != null) {
        this.dau.reverse();
        this.chart.data.datasets[0].label = label;

        var ds = this.chart.data.datasets[0];
        if (null != ds) {
          ds.backgroundColor = color;
          ds.borderColor = color;
          ds.pointBackgroundColor = color;
        }

        for (var elem of this.dau) {
          var label = formatDate(elem.item1, dateFormat, this.locale);
          this.addData(this.chart, label, elem.item2);
          console.error("-> " + elem.item1 + " -> " + elem.item2);
        }
      }
    }
  }

  updateChart2(report: Report, color: string, dateFormat: string) {
    console.error("Update Chart...");
    var ctx = "MyChart";

    if (this.chart != null) {
      this.removeData(this.chart);

      var data = this.chart.data;
      if (null != data) {
        data.labels = null;
        data.labels = [];

        for (const elem of report.labels) {
          data.labels.push(formatDate(elem, dateFormat, this.locale));
        }
        data.labels.reverse();
        //report.labels.forEach(entry => { //for (var entry in report.labels) {
        //  data.labels.push(formatDate(entry, dateFormat, this.locale));
        //}
        //data.labels = report.labels;

        var ds = data.datasets[0];
        if (null != ds) {
          ds.label = report.name;
          ds.data = report.dataSets[0];
          ds.data.reverse();

          ds.backgroundColor = color;
          ds.borderColor = color;
          ds.pointBackgroundColor = color;
        }

        this.chart.update();
      }
    }
  }


  addData(chart: Chart, label: any, newData: number) {
    console.error("Chart: " + chart);
    if (chart == null)
      return;

    if (null != chart.data) {
      console.error("Hello OK!");
      if (chart.data.labels != null) {
        chart.data.labels.push(label);
      }
      chart.data.datasets.forEach((dataset) => {
        dataset.data.push(newData);
      });
      chart.update();
    }
  }

  removeData(chart: Chart) {
    if (chart == null
      || chart.data == null
      || chart.data.labels == null)
      return;

    let total = chart.data.labels.length;

    while (total >= 0) {
      chart.data.labels.pop();
      chart.data.datasets[0].data.pop();
      total--;
    }

    chart.update();
  }

  onChangeMetric(val:any) {
    console.log("Change Tab! " + val + " -> " + this.option);
  }

  showDAU() {
    this.http.get<DailyUniqueUsers[]>(this.baseUrl + 'api/Analytics/active-users/daily').subscribe(
      {
        next: result => { 
          this.dau = result;
          this.dailyList = result;
          this.weeklyList = undefined;
          this.monthlyList = undefined;
          this.removeData(this.chart);
          this.updateChart("Daily Active Users", this.colorDAU, this.dateFormatDAU);
          console.debug(result);
        },
        error: error => {
          console.error(error)
        }
    });
  }

  showWAU() {
    this.http.get<DailyUniqueUsers[]>(this.baseUrl + 'api/Analytics/active-users/weekly').subscribe(
      {
        next: result => {
          this.dau = result;
          this.dailyList = undefined;
          this.weeklyList = result;
          this.monthlyList = undefined;
          this.removeData(this.chart);
          this.updateChart("Weekly Active Users", this.colorWAU, this.dateFormatWAU);
          console.debug(result);
        },
        error: error => {
          console.error(error)
        }
      });
  }

  showMAU3() {
    this.http.get<DailyUniqueUsers[]>(this.baseUrl + 'api/Analytics/active-users/monthly').subscribe(
      {
        next: result => {

          this.dau = result;
          this.dailyList = undefined;
          this.weeklyList = undefined;
          //this.monthlyList = result;
          this.removeData(this.chart);
          this.updateChart("Monthly Unique Users", this.colorMAU, this.dateFormatMAU);
        },
        error: error => {
          console.error(error)
        }
      });
  }

  showMAU() {
    this.http.get<Report>(this.baseUrl + 'api/Analytics/active-users/monthly2').subscribe(
      {
        next: result => {
          
          //this.dau = result;
          this.dailyList = undefined;
          this.weeklyList = undefined;
          this.monthlyList = result;
          this.removeData(this.chart);
          this.updateChart2(result, this.colorMAU, this.dateFormatMAU);          
        },
        error: error => {
          console.error(error)
        }
      });
  }

  showError(message: string) {

  }
}

interface WeatherForecast {
  date: string;
  temperatureC: number;
  temperatureF: number;
  summary: string;
}

interface DailyUniqueUsers {
  item1: Date;
  item2: number;
}


interface Report {
  name: string;
  labels: string[];
  dataSets: number[][];
}

/*
                  public Report() {
  Name = string.Empty;
  Labels = new List<string>();
  DataSets = new List<int[]>();
}

        public string Name { get; set; }

        public List < string > Labels { get; set; }

        public List < int[] > DataSets { get; set; }*/
