import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { AngularMaterialModule } from '../angular-material.module';

import { User } from '../auth/user';

@Component({
  selector: 'app-admin-user',
  templateUrl: './admin-user.component.html',
  styleUrl: './admin-user.component.css'
})
export class AdminUserComponent implements OnInit {
  public displayedColumns: string[] = ['email', 'roles'];//['id', 'email', 'isConfirmed', 'roles'];
  public users!: MatTableDataSource<User>;
  public pageEvent: PageEvent;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(@Inject('BASE_URL') private baseUrl: string,
    private http: HttpClient) {

    this.pageEvent = new PageEvent();
    this.pageEvent.pageIndex = 0;
    this.pageEvent.pageSize = 10;

  }

  ngOnInit(): void {
    this.getData(this.pageEvent);
  }

  getData(event: PageEvent) {
    var url = this.baseUrl + 'api/Account/users';
    var params = new HttpParams()
      .set("pageIndex", event.pageIndex.toString())
      .set("pageSize", event.pageSize.toString());
    this.http.get<any>(url, { params })
      .subscribe(
        {
          next: result => {
            this.paginator.length = result.totalCount;
            this.paginator.pageIndex = result.pageIndex;
            this.paginator.pageSize = result.pageSize;
            this.users = new MatTableDataSource<User>(result.data);
          },
          error: error => {
            console.error(error);
          }
        });        
  }

  revokeRole(user: User, roleName: string): void {
    var url = this.baseUrl + `api/Account/users/${user.id}/roles/${roleName}`;
    var params = new HttpParams()
    //  .set("pageIndex", event.pageIndex.toString())
    //  .set("pageSize", event.pageSize.toString());
    this.http.delete<any>(url, { params })
      .subscribe(
        {
          next: result => {
            //this.paginator.length = result.totalCount;
            //this.paginator.pageIndex = result.pageIndex;
            //this.paginator.pageSize = result.pageSize;
            //this.users = new MatTableDataSource<User>(result.data);
            console.log(result);
            this.getData(this.pageEvent);
          },
          error: error => {
            console.error(error);
          }
        });   
  }

  grantRole(user: User, roleName: string): void {
    var url = this.baseUrl + `api/Account/users/${user.id}/roles/${roleName}`;
    var params = new HttpParams()
    //  .set("pageIndex", event.pageIndex.toString())
    //  .set("pageSize", event.pageSize.toString());
    this.http.put<any>(url, { params })
      .subscribe(
        {
          next: result => {
            //this.paginator.length = result.totalCount;
            //this.paginator.pageIndex = result.pageIndex;
            //this.paginator.pageSize = result.pageSize;
            //this.users = new MatTableDataSource<User>(result.data);
            console.log(result);
            this.getData(this.pageEvent);
          },
          error: error => {
            console.error(error);
          }
        });
  }

  isRegisteredUser(arr: string[]): boolean {
    return (arr.find(x => x === "RegisteredUser") != undefined);
  }

  isAdministrator(arr: string[]): boolean {
    return (arr.find(x => x === "Administrator") != undefined);
  }

  revokeAccess(user: User): void {
    console.log("Revoke access for: " + user.email);
    this.getData(this.pageEvent);

    this.revokeRole(user, "RegisteredUser");
  }

  grantAccess(user: User): void {
    console.log("Grant access for: " + user.email);
    this.grantRole(user, "RegisteredUser");
  }

  revokeAdmin(user: User): void {
    console.log("Revoke admin for: " + user.email);

    this.revokeRole(user, "Administrator");
  }

  grantAdmin(user: User): void {
    console.log("Grant admin for: " + user.email);

    this.grantRole(user, "Administrator");
  }
}


