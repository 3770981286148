import { Component, Inject, OnInit, LOCALE_ID } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Chart } from 'chart.js/auto';
import { Report, ResponseResult, activeUserScope, updateChart, getCompanysParams } from '../util';

@Component({
  selector: 'app-engagement',
  templateUrl: './engagement.component.html',
  styleUrls: ['./engagement.component.css']
})
export class EngagementComponent implements OnInit {
  public activeUserScope = activeUserScope;
  public chart: any = null;
  public chartTitle: string = "Engagement";
  public selectedOption: string = "btnradio2";
  public option: string = "1";
  public isLoading: boolean = false;
  public scope: number = 1;

  public activeUserData?: Report;

  private lineColor: string[] = ['#007bff', '#732BF5', '#32CD32']

  private dateFormat: string[] = ['MM-dd-yyyy', 'MM-dd-yyyy', 'MMM-yyyy'];

  constructor(@Inject(LOCALE_ID) public locale: string,
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string) {

    this.showData(activeUserScope.daily);
  }

  ngOnInit(): void {
    this.createChart();
  }

  onCompanyChange() {
    this.showData(this.scope);
  }

  createChart() {
    var ctx = "MyChart";

    if (this.chart != null) {
      return;
    }
    else {
      this.chart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: [],
          datasets: [{
            label: 'Daily Active Users (DAU)',
            data: [],
            pointRadius: 1.5,
            backgroundColor: '#007bff',
            borderColor: '#007bff',
            borderWidth: 2,
            pointBackgroundColor: '#007bff'
          }]
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                color: "#b6baca",
              },
              grid: {
              },
              border: {
              },
            },
            x: {
              ticks: {
                color: "#b6baca",
              },
              grid: {
              },
              border: {
              },
            },
          },
          layout: {
            padding: 20
          },
          transitions: {
            show: {
              animations: {
                x: {
                  from: 0
                },
                y: {
                  from: 0
                }
              }
            },
            hide: {
              animations: {
                x: {
                  to: 0
                },
                y: {
                  to: 0
                }
              }
            }
          }
        },
      });

    }
  }

  onChangeMetric(val: any) {
    console.log("Change Tab! " + val + " -> " + this.option);
  }

  showData(param: number) {
    this.isLoading = true;
    this.scope = param;
    const params = new HttpParams().set('scope', param);
    this.http.get<ResponseResult<Report>>(this.baseUrl + 'api/Analytics/active-users', {params: getCompanysParams(params)}).subscribe(
      {
        next: result => {
          if (result.success) {
            this.activeUserData = result.data;
            updateChart(this.chart, result.data, this.lineColor[param - 1], this.dateFormat[param - 1], this.locale);
          } else {
            console.error(result.message);
          }
          this.isLoading = false;
        },
        error: error => {
          console.error(error);
        }
      }).add(() => {
        this.isLoading = false;
      });
  }
  showError(message: string) {

  }
}
