<main class="col-md-9 col-lg-10 px-md-4">
  <div [hidden]="!isLoading" id="pluswrap">
    <div class="plus spinner-border" style="width: 4rem; height: 4rem;" role="status">
    </div>
  </div>
  <app-page-title title="User Management" (onCompanyChange)="onCompanyChange()"></app-page-title>

  <div class="user-management-wrapper">
    <section class="user-management-container mat-elevation-z8">
      <table mat-table [dataSource]="users" class="table table-striped" matSort matSortActive="lastSignIn" matSortDirection="desc" matSortDisableClear>
        <!-- Email Column -->
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="align-middle">
            <span>Email</span>
            <app-table-sort-arrow-icon [sort]="(sortHighlight.active === 'email' ? sortHighlight.direction : '')"></app-table-sort-arrow-icon>
          </th>
          <td mat-cell *matCellDef="let user">{{user.email}}</td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="playerName">
          <th mat-header-cell *matHeaderCellDef class="align-middle">Name</th>
          <td mat-cell *matCellDef="let user">{{user.playerName}}</td>
        </ng-container>

        <!-- Company Column -->
        <ng-container matColumnDef="companyInfo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="min-width: 170px;" class="align-middle">
            <span>Company</span>
            <app-table-sort-arrow-icon [sort]="(sortHighlight.active === 'companyInfo' ? sortHighlight.direction : '')"></app-table-sort-arrow-icon>
          </th>
          <td mat-cell *matCellDef="let user">{{user.companyInfo ? (user.companyInfo.companyName || user.companyInfo.title || '-') : '-'}}</td>
        </ng-container>

        <!-- Xperiences Completed Column -->
        <ng-container matColumnDef="xperiencesCompleted">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="xapa-sort-header align-middle">
            <span>Xperiences<br/>Completed</span>
            <app-table-sort-arrow-icon [sort]="(sortHighlight.active === 'xperiencesCompleted' ? sortHighlight.direction : '')"></app-table-sort-arrow-icon>
          </th>
          <td mat-cell *matCellDef="let user" class="text-center">{{user.xperiencesCompleted}}</td>
        </ng-container>
        
        <!-- Quests Completed / Quests Started Column -->
        <ng-container matColumnDef="questCompleted">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="xapa-sort-header align-middle">
            <span>Quests<br/>Completed/Started</span>
            <app-table-sort-arrow-icon [sort]="(sortHighlight.active === 'questCompleted' ? sortHighlight.direction : '')"></app-table-sort-arrow-icon>
          </th>
          <td mat-cell *matCellDef="let user" class="text-center">
            <span>{{user.questCompleted}} / {{user.questStarted}}</span>
          </td>
        </ng-container>
      
        <!-- Since Column(date they created the account) -->
        <ng-container matColumnDef="since">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="xapa-sort-header align-middle">
            <span>Since</span>
            <app-table-sort-arrow-icon [sort]="(sortHighlight.active === 'since' ? sortHighlight.direction : '')"></app-table-sort-arrow-icon>
          </th>
          <td mat-cell *matCellDef="let user" class="text-center">{{user.since | date: 'M/d/yyyy'}}</td>
        </ng-container>

        <!-- Session Count Column -->
        <ng-container matColumnDef="sessionCount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="xapa-sort-header align-middle">
            <span>Session<br/>Count</span>
            <app-table-sort-arrow-icon [sort]="(sortHighlight.active === 'sessionCount' ? sortHighlight.direction : '')"></app-table-sort-arrow-icon>
          </th>
          <td mat-cell *matCellDef="let user" class="text-center">{{user.sessionCount}}</td>
        </ng-container>

        <!-- Last Sign-in Column -->
        <ng-container matColumnDef="lastSignIn">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="xapa-sort-header align-middle" style="min-width: 100px;">
            <span style="white-space: nowrap;">Last Sign-in</span>
            <app-table-sort-arrow-icon [sort]="(sortHighlight.active === 'lastSignIn' ? sortHighlight.direction : '')"></app-table-sort-arrow-icon>
          </th>
          <td mat-cell *matCellDef="let user" class="text-center">{{user.lastSignIn | date: 'M/d/yyyy HH:mm:ss'}}</td>
        </ng-container>

        <!-- Assign/Edit Column -->
        <ng-container matColumnDef="operation" stickyEnd>
          <th mat-header-cell *matHeaderCellDef class="text-center align-middle">Operation</th>
          <td mat-cell *matCellDef="let user" class="text-center">
            <img src="/assets/img/edit.svg" (click)="openEditDialog(user)" style="width: 22px; height: 22px; cursor: pointer;" >
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="table-head"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-row"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" style="text-align: center;" colspan="9">No data</td>
        </tr>
      </table>
    </section>

    <!-- Pagination directive -->
    <mat-paginator
      #matPaginator
      (page)="getTableData($event)"
      [pageSize]="20"
      [pageSizeOptions]="[20, 50, 100]"
      showFirstLastButtons>
    </mat-paginator>
  </div>
</main>
