import { Component, OnInit } from '@angular/core';
import * as feather from 'feather-icons';
import { AuthService } from './auth/auth.service';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit {
  title = 'XapaDashboard';

  private destroySubject = new Subject();
  isLoggedIn: boolean = false;
  isExpanded = false;

  constructor(private authService: AuthService,
    private router: Router) {

    this.authService.authStatus
      .pipe(takeUntil(this.destroySubject))
      .subscribe(result => {
        this.isLoggedIn = result;
      });
  }

  ngOnInit(): void {   
    feather.replace({ 'aria-hidden': 'true' });
    this.authService.init();
  }

  onLogout(): void {
    this.authService.logout();
    this.router.navigate(["/"]);
  }

  isRegisteredUser(): boolean {
    return this.authService.isRegisteredUser();
  }
}
