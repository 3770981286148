<mat-dialog-content class="dialog-content">
  <div class="my-2"><label>Email:&nbsp;</label>{{ email }}</div>
  <div class="mb-3"><label>Player:&nbsp;</label>{{ playerName }}</div>
  <mat-form-field class="company-dialog-select">
    <mat-label>Select an Company</mat-label>
    <mat-select [formControl]="selectedCompany" panelClass="xapa-select-panel" (opened)="panelOpen = true" (closed)="panelOpen = false">
      <mat-select-trigger>
        <div class="select-trigger">
          <span class="selected-company-name d-inline-block text-truncate">{{ selectedCompanyName || '' }}</span>
          @if (selectedCompany.value && !panelOpen) {
          <img src="/assets/img/clean.svg" class="clean" (click)="clean($event)">
          }
        </div>
      </mat-select-trigger>
      @for (company of companyList; track company) {
        <mat-option
          [value]="company['title']"
          [class.null-option]="'*none*' === company['title']"
        >
          {{company['companyName'] || company['title']}}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions [align]="'end'">
  <button mat-button (click)="onCancelClick()">Cancel</button>
  <button mat-button color="primary" [mat-dialog-close]="{'selectedCompany': selectedCompany.value, 'playerName': playerName}" [disabled]="isDisabled">Ok</button>
</mat-dialog-actions>

