import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';

import { HomeComponent } from './home/home.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { LoginComponent } from './auth/login.component';
import { RegisterComponent } from './auth/register.component';
import { AdminUserComponent } from './admin-user/admin-user.component';
import { EngagementComponent } from './engagement/engagement.component';
import { UserBehaviorComponent } from './user-behavior/user-behavior.component';
import { QuestAnalyticsComponent } from './quest-analytics/quest-analytics.component';
import { RetentionComponent } from './retention/retention.component';
import { LandingComponent } from './landing/landing.component';
import { ProfileComponent } from './profile/profile.component';
import { UnregisteredComponent } from './unregistered/unregistered.component';
import { UserManagementComponent } from './user-management/user-management.component';

const routes: Routes = [
  { path: '', component: LandingComponent, pathMatch: 'full' },
  { path: 'landing', component: LandingComponent, pathMatch: 'full' },

  { path: 'login', component: LoginComponent, pathMatch: 'full' },
  { path: 'register', component: RegisterComponent, pathMatch: 'full' },

  { path: 'unregistered', component: UnregisteredComponent, pathMatch: 'full' },

  { path: 'counter', component: CounterComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'fetch-data', component: FetchDataComponent, pathMatch: 'full', canActivate: [AuthGuard] },

  { path: 'home', component: HomeComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'engagement', component: EngagementComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'retention', component: RetentionComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'user-behavior', component: UserBehaviorComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'quest-analytics', component: QuestAnalyticsComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'user-management', component: UserManagementComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'profile', component: ProfileComponent, pathMatch: 'full', canActivate: [AuthGuard] },

  { path: 'admin-user', component: AdminUserComponent, pathMatch: 'full', canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
