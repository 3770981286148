import { Component, Inject, OnInit, ViewChild, ElementRef, HostListener, AfterViewInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { questSelectType, ResponseResult, getCompanysParams } from '../util';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-quest-analytics',
  templateUrl: './quest-analytics.component.html',
  styleUrl: './quest-analytics.component.css'
})
export class QuestAnalyticsComponent implements OnInit, AfterViewInit {

  public isLoading: boolean = false;
  public isTableLoading: boolean = false;

  public totalCount: number = 0;

  public pageEvent: PageEvent;

  questSelectType = questSelectType;

  //drop down 
  xperiences = new FormControl<string[]>({ value: [], disabled: false });
  quests = new FormControl<string[]>({ value: [], disabled: true });
  nodeTitles = new FormControl<string[]>({ value: [], disabled: true });
  xperiencesOptionalList: string[] = [];
  questsOptionalList: string[] = [];
  nodeTitlesOptionalList: string[] = [];

  //all select
  allSelectColor = "primary";

  allXperiencesSelected: boolean = false;
  allQuetsSelected: boolean = false;
  allNodeTitlesSelected: boolean = false;

  isXperienceIndeterminate: boolean = false;
  isQuestIndeterminate: boolean = false;
  isNodeTitleIndeterminate: boolean = false;

  lastXperienceSelected: string [] = [];
  lastQuetsSelected: string [] = [];
  lastNodeTitlesSelected: string [] = [];

  //table
  displayedColumns: string[] = ['ResultText', 'Count', 'Percent'];
  dataSource! : MatTableDataSource<ResponseData>;
  tableHeaderDynamicStyles = {
    top: '0px',
  };

  isRequested: boolean = false;

  private reqData: RequestData = {
    xperienceIds: [],
    questIds: [],
    nodeTitles: [],
    pageIndex: 0,
    pageSize: 0
  };

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild('questTitle', { static: true }) questTitle!: ElementRef;

  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private viewportScroller: ViewportScroller) {
    this.getFilterOptional([], []);
    this.pageEvent = new PageEvent();
    this.pageEvent.pageIndex = 0;
    this.pageEvent.pageSize = 20;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.getTableHeadTop();
  }

  ngOnInit(): void {
    this.xperiences.valueChanges.subscribe(value => {
      this.dataSource = new MatTableDataSource<ResponseData>([]);
      this.isRequested = false;
      if (value != null && value.length > 0) {
        this.quests.reset({ value: [], disabled: false });
        this.nodeTitles.reset({ value: [], disabled: true });
        this.isXperienceIndeterminate = value.length < this.xperiencesOptionalList.length;
        this.allXperiencesSelected = value.length == this.xperiencesOptionalList.length;
      } else {
        this.quests.reset({ value: [], disabled: true });
        this.nodeTitles.reset({ value: [], disabled: true });
        this.isXperienceIndeterminate = false;
        this.allXperiencesSelected = false;
      }
    });

    this.quests.valueChanges.subscribe(value => {
      this.dataSource = new MatTableDataSource<ResponseData>([]);
      if (value != null && value.length > 0) {
        this.nodeTitles.reset({ value: [], disabled: false });
        this.isQuestIndeterminate = value.length < this.questsOptionalList.length;
        this.allQuetsSelected = value.length == this.questsOptionalList.length;
      } else {
        this.nodeTitles.reset({ value: [], disabled: true });
        this.isQuestIndeterminate = false;
        this.allQuetsSelected = false;
      }
    });

    this.nodeTitles.valueChanges.subscribe(value => {
      this.dataSource = new MatTableDataSource<ResponseData>([]);
      if (value != null && value.length > 0) {
        this.isNodeTitleIndeterminate = value.length < this.nodeTitlesOptionalList.length;
        this.allNodeTitlesSelected = value.length == this.nodeTitlesOptionalList.length;
      } else {
        this.isNodeTitleIndeterminate = false;
        this.allNodeTitlesSelected = false;
      }
    });
  }

  ngAfterViewInit() {
    this.getTableHeadTop();
  }

  getTableHeadTop() {
    const element = this.questTitle.nativeElement;
    const rect = element.getBoundingClientRect();
    this.tableHeaderDynamicStyles.top = rect.bottom + 'px';
  }

  toggleAllSelection(selectType: questSelectType) {
    switch (selectType) {
      case questSelectType.xperience:
        this.allXperiencesSelected = !this.allXperiencesSelected;
        this.xperiences.setValue(this.allXperiencesSelected ? this.xperiencesOptionalList : []);
        break;
      case questSelectType.quest:
        this.allQuetsSelected = !this.allQuetsSelected;
        this.quests.setValue(this.allQuetsSelected ? this.questsOptionalList : []);
        break;
      case questSelectType.nodeTitle:
        this.allNodeTitlesSelected = !this.allNodeTitlesSelected;
        this.nodeTitles.setValue(this.allNodeTitlesSelected ? this.nodeTitlesOptionalList : []);
        break;
      default:
        break;
    }
  }

  getFilterOptional(xperiences: string[], quests: string[]) {
    this.isLoading = true;
    this.reqData.xperienceIds = xperiences;
    this.reqData.questIds = quests;
    this.http.post<ResponseResult<any>>(this.baseUrl + 'api/Analytics/quest-filterlist', this.reqData, {
      params: getCompanysParams(new HttpParams())
    }).subscribe(
      {
        next: result => {
          if (result.success) {
            if ("XperienceId" == result.data.name) {
              this.xperiencesOptionalList = result.data.records;
            }
            if ("QuestId" == result.data.name) {
              this.questsOptionalList = result.data.records;
            }
            if ("NodeTitle" == result.data.name) {
              this.nodeTitlesOptionalList = result.data.records;
            }
          } else {
            console.error(result.message);
          }
          this.isLoading = false;
        },
        error: error => {
          console.error(error);
        }
      }).add(() => {
        this.isLoading = false;
      });
  }

  getTableData(xperiences: string[], quests: string[], nodeTitles: string[], event: PageEvent) {
    if (nodeTitles.length === 0) {
      this.dataSource.data = [];
      return;
    }
    this.lastNodeTitlesSelected = nodeTitles;
    this.isLoading = true;
    this.reqData.xperienceIds = xperiences;
    this.reqData.questIds = quests;
    this.reqData.nodeTitles = nodeTitles;
    this.reqData.pageIndex = event.pageIndex;
    this.reqData.pageSize = event.pageSize;
    this.http.post<any>(this.baseUrl + 'api/Analytics/quest-reportcontent', this.reqData, {
      params: getCompanysParams(new HttpParams())
    }).subscribe(
      {
        next: result => {
          if (result.success) {
            this.paginator.length = result.totalCount;
            this.paginator.pageIndex = result.pageIndex;
            this.paginator.pageSize = result.pageSize;
            if (result.data.length >= 1) {
              this.totalCount = result.data[0].totalResultCount;
            }
            this.dataSource = new MatTableDataSource<ResponseData>(result.data);
            this.isRequested = true;
          } else {
            console.error(result.message);
          }
          this.isLoading = false;
          this.isTableLoading = false;
          this.getTableHeadTop();
       },
        error: error => {
          console.error(error);
        }
      }
    ).add(() => {
      this.isLoading = false;
      this.viewportScroller.scrollToPosition([0, 0]);
      this.isTableLoading = false;
      this.isRequested = false;
      this.getTableHeadTop();
    });
  }

  onCompanyChange() {
    this.xperiences.reset({ value: [], disabled: false });
    this.getFilterOptional([], []);
  }
}

interface RequestData {
  xperienceIds: string[];
  questIds: string[];
  nodeTitles: string[];
  pageIndex: number;
  pageSize: number;
}

interface ResponseData {
  resultText: string;
  count: number;
  percentage: number;
}


