import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, Inject, LOCALE_ID } from '@angular/core';
import { Chart } from 'chart.js';
import { Report, ResponseResult, updateChart, userBehaviorType, getCompanysParams } from '../util';

@Component({
  selector: 'app-user-behavior',
  templateUrl: './user-behavior.component.html',
  styleUrl: './user-behavior.component.css'
})
export class UserBehaviorComponent {
  public userBehaviorType = userBehaviorType;
  public userBehaviorData?: Report;
  public chart: any = null;
  public chartTitle: string = "User Behavior";
  public selectedOption: string = "btnradio2";
  public option: string = "1";
  public isLoading: boolean = false;
  public type: userBehaviorType = 1;


  private lineColor: string[] = ['#007bff', '#32CD32'];

  private dateFormat: string = 'MM-dd-yyyy';

  constructor(@Inject(LOCALE_ID) public locale: string,
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string) {

    this.showUserBehaviorData(1);
  }

  ngOnInit(): void {
    this.createChart();
  }

  onCompanyChange() {
    this.showUserBehaviorData(this.type);
  }

  createChart() {
    var ctx = "BehaviorChart";

    if (this.chart != null) {
      return;
    }
    else {

      this.chart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: [], 
          datasets: [{
            label: 'User Behavior(Time)',
            data: [],
            pointRadius: 1.5,
            backgroundColor: '#007bff',
            borderColor: '#007bff',
            borderWidth: 2,
            pointBackgroundColor: '#007bff'
          }]
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                color: "#b6baca",
                callback: function(value) {
                  var label = this.chart.data.datasets[0].label;
                  if (label != null && label != undefined) {
                    return label.indexOf("Time") == -1 ? value : value + " (h)";
                  }
                  return value;
                }
              },
              grid: {
              },
              border: {
              },
            },
            x: {
              ticks: {
                color: "#b6baca",
              },
              grid: {
              },
              border: {
              },
            },
          },
          layout: {
            padding: 20
          },
          transitions: {
            show: {
              animations: {
                x: {
                  from: 0
                  
                },
                y: {
                  from: 0
                }
              }
            },
            hide: {
              animations: {
                x: {
                  to: 0
                },
                y: {
                  to: 0
                }
              }
            }
          },
          plugins: {
            tooltip: {
              callbacks: {
                label: function(context) {
                  if (context.dataset.label?.indexOf("Time") !== -1){
                    return context.dataset.label + ": " + context.parsed.y + " (h)";
                  }
                  return context.dataset.label + ": " + context.parsed.y;
                }
              }
            }
          }
        },
      });

    }
  }

  onChangeMetric(val: any) {
    console.log("Change Tab! " + val + " -> " + this.option);
  }

  showUserBehaviorData(param: userBehaviorType) {
    this.isLoading = true;
    this.type = param;
    const params = new HttpParams().set('type', param);
    this.http.get<ResponseResult<Report>>(this.baseUrl + 'api/Analytics/average-egagement', {params: getCompanysParams(params)}).subscribe(
      {
        next: result => {
          if (result.success) {
            this.userBehaviorData = result.data;
            updateChart(this.chart, result.data, this.lineColor[param - 1], this.dateFormat, this.locale);
          } else {
            console.error(result.message);
          }
          this.isLoading = false;
        },
        error: error => {
          console.error(error);
        }
      }).add(() => {
        this.isLoading = false;
      });
  }

  showError(message: string) {

  }
}
