<div class="container text-center">
  <div class="login form-signin text-center">
    <img class="logo-badge mb-4" src="/assets/img/xapa-logo.png" alt="">
    <p class="lead">Please sign in</p>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">

      <p>
        <mat-error *ngIf="loginResult && !loginResult.success">
          {{loginResult.message}}
        </mat-error>
      </p>

      <!-- email -->
      <mat-form-field>
        <mat-label>Email:</mat-label>
        <input matInput formControlName="email" required placeholder="Enter your email">
        <mat-error *ngFor="let error of getErrors(form.get('email')!, 'Email')">
          {{error}}
        </mat-error>
      </mat-form-field>

      <!-- password -->
      <mat-form-field>
        <mat-label>Password:</mat-label>
        <input matInput type="password" formControlName="password" required placeholder="Enter your password">
        <mat-error *ngFor="let error of getErrors(form.get('password')!, 'Password')">
          {{error}}
        </mat-error>
      </mat-form-field>

      <!--
  <button mat-flat-button color="primary" type="submit">
    Login
  </button>
  <button mat-flat-button color="secondary" [routerLink]="['/']">
    Cancel
  </button>
    -->
      <button class="w-100 btn btn-lg btn-primary" type="submit">
        Sign in
      </button>

      <div class="p-4"><a [routerLink]="['/register']">Don't have an account? Create one!</a></div>


      <p class="mt-5 mb-3 text-muted">&copy; 2017–2024</p>

    </form>
  </div>
</div>
