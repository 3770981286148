import { Inject, Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, tap } from 'rxjs';
import { LoginRequest } from './login-request';
import { LoginResult } from './login-result';
import { RegistrationResult } from './registration-result';
import { RegistrationRequest } from './registration-request';
import { jwtDecode } from 'jwt-decode';
import { Router } from '@angular/router';
import { ResetPasswordRequest } from './reset-password-request';
import { ResetPasswordResult } from './reset-password-result';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    protected http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private router: Router ) {
  }

  private tokenKey: string = "token";
  private _authStatus: Subject<boolean> = new Subject<boolean>();
  public authStatus: Observable<boolean> = this._authStatus.asObservable();

  isAuthenticated(): boolean {
    return (this.getToken() !== null);
  }

  isAdmin(): boolean {
    return (this.getRoles().find(role => role == "Administrator") != undefined);
  }

  isRegisteredUser(): boolean {
    return (this.getRoles().find(role => role == "RegisteredUser") != undefined);
  }

  getToken(): string | null {
    return localStorage.getItem(this.tokenKey);
  }

  getEmail(): string {
    var token = this.getToken();

    if (token == null) {
      return "";
    }

    var payload = jwtDecode<GCNPayload>(token);
    return payload.email;
  }

  getRoles(): string[] {
    var token = this.getToken();

    if (token == null) {
      return [];
    }

    var payload = jwtDecode<GCNPayload>(token);

    if (typeof payload.roles == "string")
      return [payload.roles];

    return payload.roles != null ? payload.roles : [];
  }

  init(): void {
    if (this.isAuthenticated()) {
      this.setAuthStatus(true);
      this.router.navigate(["/home"]);
    }
  }

  register(item: RegistrationRequest): Observable<RegistrationResult> {
    var url = this.baseUrl + "api/Account/Register";
    return this.http.post<RegistrationResult>(url, item)
      .pipe(tap(registrationResult => {
        if (registrationResult.success) {
          //localStorage.setItem(this.tokenKey, registrationResult.token);
          //this.setAuthStatus(true);
        }
      }));
  }

  login(item: LoginRequest): Observable<LoginResult> {
    var url = this.baseUrl + "api/Account/Login";
    return this.http.post<LoginResult>(url, item)
      .pipe(tap(loginResult => {
        if (loginResult.success && loginResult.token) {
          localStorage.setItem(this.tokenKey, loginResult.token);
          localStorage.removeItem('companyTitles');
          this.setAuthStatus(true);
        }
      }));
  }

  logout() {
    localStorage.removeItem(this.tokenKey);
    localStorage.removeItem('companyTitles');
    this.setAuthStatus(false);
  }

  resetPassword(item: ResetPasswordRequest): Observable<ResetPasswordResult> {
    var url = this.baseUrl + "api/Account/ResetPassword";
    return this.http.post<ResetPasswordResult>(url, item)
      .pipe(tap(result => {
        if (result.success) {
          //localStorage.setItem(this.tokenKey, registrationResult.token);
          //this.setAuthStatus(true);
        }
      }));
  }

  private setAuthStatus(isAuthenticated: boolean): void {
    this._authStatus.next(isAuthenticated);
  }
}

interface GCNPayload {
  email: string;
  roles: string[];
}
